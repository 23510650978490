import React, { useEffect, useState } from "react";
import "./keapFileMerge.css";
import Tracking from "../../../../components/TrackingComp";
import {
  MAKE_KEAP_FILE_MERGE_CALL,
  RETRIEVE_KEAP_FILE,
} from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import { Snackbar, Switch, FormControlLabel } from "@mui/material";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { CircularProgress } from "@mui/material";

const KeapFileMerge = () => {
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });
  const [KeapFileIdInput, setKeapFileIdInput] = useState("");
  const [searchForSingleFileOrMerge, setSearchForSingleFileOrMerge] =
    useState(false);
  const [fileData, setFileData] = useState("");
  const [makeKeapFetchCall] = useMutation(RETRIEVE_KEAP_FILE);
  const [makeKeapMergeCall] = useMutation(MAKE_KEAP_FILE_MERGE_CALL);
  const [initialFetch, setInitialFetch] = useState(true);
  const [disableFetchBtn, setDisableFetchBtn] = useState(false);
  const [endOfFetch, setEndOfFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [autoFetch, setAutoFetch] = useState(false);
  const [autoFetchCounter, setAutoFetchCounter] = useState(null);
  const [limitOffsetFetch, setLimitOffsetFetch] = useState({
    limit: "",
    offset: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLimitOffsetFetch({
      limit: "",
      offset: "",
    });
    setAutoFetchCounter(null);
    setAutoFetch(false);
    setLoading(false);
    setEndOfFetch(false);
    setDisableFetchBtn(false);
    setInitialFetch(true);
    setFileData("");
    setLoading2(false);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForSingleFileOrMerge]);

  useEffect(() => {
    if (
      limitOffsetFetch?.limit?.length > 0 &&
      limitOffsetFetch?.offset?.length > 0 &&
      autoFetch &&
      initialFetch === false
    ) {
      if (endOfFetch) {
        setAutoFetch(false);
        handleSnack("No more to fetch...", 3000);
        return;
      }

      let counterId = "";
      if (autoFetchCounter <= 0 && loading === false) {
        setLoading(true);
        handleMergeCall();
      }

      if (autoFetchCounter > 0) {
        counterId = setInterval(() => {
          setAutoFetchCounter((prev) => prev - 1);
        }, 1000);
      }

      return () => {
        clearInterval(counterId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFetchCounter, autoFetch]);

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setKeapFileIdInput(value);
  };

  const handleInputSubmit = async (event) => {
    const value = event?.key;
    console.log(value);
    if (value === "Enter") {
      handleFetch();
      setLoading2(true);
    }

    return;
  };

  const handleFetch = async () => {
    const { data } = await makeKeapFetchCall({
      variables: {
        keapFileId: KeapFileIdInput,
      },
    });

    // data?.retrieveKeapFile
    // console.log(data?.retrieveKeapFile);
    if (data?.retrieveKeapFile?.length > 0) {
      handleSnack("File Found", 2000);
      setFileData(`data:image/jpeg;base64, ${data?.retrieveKeapFile}`);
      setLoading2(false);
      return;
    }

    setLoading2(false);
    handleSnack("File Not Found", 5000);
    setFileData(``);
  };

  const handleMergeCall = async () => {
    const { data } = await makeKeapMergeCall({
      variables: {
        ...limitOffsetFetch,
      },
    });

    console.log(data)
    const moreFiles = data?.makeKeapFilesMergeCall

    if (moreFiles) {
      handleSnack("Successful Fetch", 1000);
      setLimitOffsetFetch((prev) => {
        const prevOffset = parseInt(prev.offset);
        const limit = parseInt(prev.limit);
        const nextOffset = (prevOffset + limit)?.toString();

        return { ...prev, offset: nextOffset };
      });
    }

    setInitialFetch(false);
    setLoading(false);

    if (moreFiles === false) {
      setDisableFetchBtn(true);
      setEndOfFetch(true);
      setAutoFetch(false);
      setAutoFetchCounter(null);
      return;
    }

    if (autoFetch) {
      setAutoFetchCounter(3);
    }

    setDisableFetchBtn(false);
  };

  const handleSearchForSingleFileOrMerge = (bool) => {
    setSearchForSingleFileOrMerge(bool);
  };

  const handleFetchObjUpdate = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setLimitOffsetFetch((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="keap-file-merge-page">
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="keap-file-merge-box">
        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={searchForSingleFileOrMerge}
              onChange={(event) => {
                handleSearchForSingleFileOrMerge(event.target.checked);
              }}
              inputProps={{ "aria-label": "Auto Fetch" }}
            />
          }
          label={searchForSingleFileOrMerge ? "Find File" : "Merge Files"}
          labelPlacement="start"
        />
      </div>
      {searchForSingleFileOrMerge ? (
        <div className="keap-file-merge-box">
          {loading2 && (
            <div
              onClick={(event) => {
                event?.stopPropagation();
              }}
              className="keap-merge-overlay-circle-box"
            >
              <CircularProgress color="inherit" size={30} />
            </div>
          )}
          <h1>Keap File Search</h1>
          <CssTextFieldStandard
            sx={{ width: "45%" }}
            type="text"
            label="File Id"
            name="fileId"
            onKeyUp={handleInputSubmit}
            onChange={handleInputChange}
            value={KeapFileIdInput}
            variant="standard"
          />
          <button
            className="keap-file-merge-btn"
            onClick={() => {
              handleFetch();
              setLoading2(true);
            }}
          >
            Fetch
          </button>
          {fileData?.length > 0 && (
            <img src={fileData} className="keap-file-merge-img" alt="" />
          )}
        </div>
      ) : (
        <div className="keap-file-merge-box">
          {loading && (
            <div
              onClick={(event) => {
                event?.stopPropagation();
              }}
              className="keap-merge-overlay-circle-box"
            >
              <CircularProgress color="inherit" size={30} />
            </div>
          )}
          <h1>Keap File Merge Controller</h1>
          <div className="keap-merge-input-box">
            <CssTextFieldStandard
              sx={{ width: "45%" }}
              type="text"
              label="Fetch Limit"
              name="limit"
              onChange={handleFetchObjUpdate}
              value={limitOffsetFetch?.limit}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "45%" }}
              type="text"
              label={initialFetch ? "Fetch Offset" : `Next Fetch Offset`}
              name="offset"
              onChange={handleFetchObjUpdate}
              value={limitOffsetFetch?.offset}
              variant="standard"
            />
          </div>
          <div className="keap-merge-btn-box">
            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={autoFetch}
                  onChange={(event) => {
                    setAutoFetch(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "Auto Fetch" }}
                />
              }
              label="Auto-Fetch"
              labelPlacement="start"
            />

            <button
              className={
                endOfFetch ? "keap-merge-btn disabled" : "keap-merge-btn"
              }
              onClick={(event) => {
                handleMergeCall(event);
                setDisableFetchBtn(true);
                setLoading(true);
              }}
              disabled={disableFetchBtn}
            >
              {initialFetch
                ? "Merge Files"
                : endOfFetch
                ? "No More To Merge"
                : autoFetch
                ? `Merging${
                    autoFetchCounter > 0
                      ? ` Next ${limitOffsetFetch?.limit} in ${autoFetchCounter}`
                      : ""
                  }...`
                : `Merge Next ${limitOffsetFetch?.limit}`}
            </button>
          </div>
        </div>
      )}

      <Tracking removeVisit={true} />
    </div>
  );
};

export default KeapFileMerge;
