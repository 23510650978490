import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
      user {
        _id
        firstName
        lastName
        email
        phoneNumberIds {
          phoneNumber
          numberType
          title
          textMessages {
            body
            sentReceivedTime
            outgoingBool
            twilioSID
            attachedImgs
          }
        }
        permission
        initLogin
        failedLogins
        streetAddress
        city
        state
        zipCode
        usesWebAuthn
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($username: String!, $email: String!, $password: String!) {
    addUser(username: $username, email: $email, password: $password) {
      token
      user {
        _id
        username
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage(
    $nameInput: String!
    $emailInput: String!
    $phoneNumberInput: String!
    $numberType: String!
    $textConsent: Boolean!
    $intExtChoice: String!
    $discountChoice: String!
    $heardAboutUs: String
    $otherInput: String
    $imageIdArr: [String]
  ) {
    sendMessage(
      nameInput: $nameInput
      emailInput: $emailInput
      phoneNumberInput: $phoneNumberInput
      numberType: $numberType
      textConsent: $textConsent
      intExtChoice: $intExtChoice
      discountChoice: $discountChoice
      heardAboutUs: $heardAboutUs
      otherInput: $otherInput
      imageIdArr: $imageIdArr
    ) {
      message
      error
    }
  }
`;

export const NEW_CLIENT_PHOTO = gql`
  mutation storeImage(
    $imageData: String
    $beforeOrAfter: String
    $dateEntered: String
    $assignToClientId: String
  ) {
    storeImage(
      imageData: $imageData
      beforeOrAfter: $beforeOrAfter
      dateEntered: $dateEntered
      assignToClientId: $assignToClientId
    ) {
      _id
      dateEntered
    }
  }
`;

export const DELETE_BA_GROUP = gql`
  mutation deleteBAGroup($baGroupId: String!) {
    deleteBAGroup(BAGroupId: $baGroupId)
  }
`;

export const CREATE_BA_GROUP = gql`
  mutation createBAGroup(
    $beforeId: String!
    $afterId: String!
    $assignToClientId: String
    $weekGalleryDisplay: Boolean!
    $dateEntered: String
    $refinishers: [String]
    $stainColor: String
  ) {
    createBAGroup(
      beforeId: $beforeId
      afterId: $afterId
      assignToClientId: $assignToClientId
      weekGalleryDisplay: $weekGalleryDisplay
      dateEntered: $dateEntered
      refinishers: $refinishers
      stainColor: $stainColor
    ) {
      _id
      beforeId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      afterId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      weekGalleryDisplay
      dateEntered
      refinishers
      stainColor
    }
  }
`;

export const UPDATE_FINEST_JOBS = gql`
  mutation updateFinestJobs($BAGroupIdArr: [String]) {
    updateFinestJobs(BAGroupIdArr: $BAGroupIdArr) {
      _id
      BAGroupIds {
        _id
        beforeId {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        afterId {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        weekGalleryDisplay
        dateEntered
        stainColor
        refinishers
      }
    }
  }
`;

export const ADD_SHOP_ITEM = gql`
  mutation addShopInventoryItem(
    $itemName: String!
    $currentQuantity: String!
    $maxQuantity: String!
    $price: String!
  ) {
    addInventoryItem(
      itemName: $itemName
      currentQuantity: $currentQuantity
      maxQuantity: $maxQuantity
      price: $price
    ) {
      id
      itemName
      currentQuantity
      maxQuantity
      price
    }
  }
`;

export const UPDATE_SHOP_ITEM = gql`
  mutation UpdateShopItem(
    $itemId: String!
    $currentQuantity: String!
    $maxQuantity: String!
    $price: String!
  ) {
    updateInventoryItem(
      itemId: $itemId
      currentQuantity: $currentQuantity
      maxQuantity: $maxQuantity
      price: $price
    ) {
      id
      itemName
      currentQuantity
      maxQuantity
      price
    }
  }
`;

export const DELETE_SHOP_ITEM = gql`
  mutation Mutation($itemId: String!) {
    deleteInventoryItem(itemId: $itemId)
  }
`;

export const UPDATE_REFIN_INV = gql`
  mutation updatedUserInvReq($itemsIdAndCountArr: [RefinReqItems]) {
    updateRefinInvReq(itemsIdAndCountArr: $itemsIdAndCountArr) {
      firstName
      refinReqInv {
        reqItemId
        reqItemCount
      }
      _id
      lastName
      email
      permission
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
        textMessages {
          body
          sentReceivedTime
          outgoingBool
          twilioSID
          attachedImgs
        }
      }
      initLogin
      failedLogins
      streetAddress
      city
      state
      zipCode
      active
    }
  }
`;

export const CLEAR_REFIN_INV = gql`
  mutation clearRefinInvReq {
    clearRefinInvReq {
      firstName
      refinReqInv {
        reqItemId
        reqItemCount
      }
      _id
      lastName
      email
      permission
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
      }
      initLogin
      failedLogins
      streetAddress
      city
      state
      zipCode
      active
      active
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUser(
    $updateUserId: String!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumberIds: [PhoneNumUpdate]
    $streetAddress: String
    $city: String
    $state: String
    $zipCode: String
    $newPass: String
    $prevPass: String
    $initLogin: Boolean
  ) {
    updateUser(
      id: $updateUserId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumberIds: $phoneNumberIds
      streetAddress: $streetAddress
      city: $city
      state: $state
      zipCode: $zipCode
      newPass: $newPass
      prevPass: $prevPass
      initLogin: $initLogin
    ) {
      token
      user {
        _id
        firstName
        lastName
        email
        phoneNumberIds {
          _id
          phoneNumber
          numberType
          title
          textMessages {
            body
            sentReceivedTime
            outgoingBool
            twilioSID
            attachedImgs
          }
        }
        permission
        initLogin
        failedLogins
        streetAddress
        city
        state
        zipCode
        refinReqInv {
          reqItemId
          reqItemCount
        }
        active
        usesWebAuthn
      }
    }
  }
`;

export const SEND_NEW_INVITE = gql`
  mutation SendInviteEmail(
    $emailToInvite: String
    $firstName: String
    $permission: String
  ) {
    sendInviteEmail(
      emailToInvite: $emailToInvite
      firstName: $firstName
      permission: $permission
    )
  }
`;

export const SEND_TEMP_PASS = gql`
  mutation Mutation($emailTo: String) {
    sendForgotPasswordEmail(emailTo: $emailTo)
  }
`;

export const DEACTIVATE_USER = gql`
  mutation Mutation($idDelete: String!) {
    deactivateUser(idDelete: $idDelete)
  }
`;

export const ARCHIVE_EMAIL = gql`
  mutation Mutation($emailId: String!, $changeArchive: Boolean!) {
    archiveEmail(emailId: $emailId, changeArchive: $changeArchive)
  }
`;

export const DELETE_EMAIL = gql`
  mutation Mutation($emailId: String!) {
    deleteEmailById(emailId: $emailId)
  }
`;

export const NEW_VISIT = gql`
  mutation Mutation(
    $timeVisited: String!
    $signNum: String
    $pageVisited: String
  ) {
    newVisitor(
      timeVisited: $timeVisited
      signNum: $signNum
      pageVisited: $pageVisited
    ) {
      id
      ifQuote
      pagesVisited {
        home
        quote
        ourDoors
        about
        contact
      }
      timeVisited
      fromSign
    }
  }
`;

export const UPDATE_VISIT = gql`
  mutation Mutation(
    $updateVisitId: String
    $page: String
    $convertQuote: Boolean
  ) {
    updateVisit(id: $updateVisitId, page: $page, convertQuote: $convertQuote) {
      id
      timeVisited
      pagesVisited {
        home
        quote
        ourDoors
        about
        contact
      }
      ifQuote
      fromSign
    }
  }
`;

export const DELETE_VISIT = gql`
  mutation Mutation($deleteVisitId: String!) {
    deleteVisit(id: $deleteVisitId)
  }
`;

export const CREATE_SIGN_ASSIGN = gql`
  mutation CreateSignAssignment($signNum: String!, $signName: String!) {
    createSignAssignment(signNum: $signNum, signName: $signName) {
      id
      signNum
      signName
    }
  }
`;

export const UPDATE_SIGN_ASSIGN = gql`
  mutation UpdateSignAssignment($signNum: String!, $signName: String!) {
    updateSignAssignment(signNum: $signNum, signName: $signName) {
      id
      signNum
      signName
    }
  }
`;

export const DELETE_SIGN_ASSIGN = gql`
  mutation DeleteSignAssignment($id: String!) {
    deleteSignAssignment(id: $id)
  }
`;

export const SEND_EMAIL_TEMPLATE = gql`
  mutation SendEmailTemp(
    $to: String!
    $from: String!
    $subject: String!
    $text: String
    $type: String!
    $attachArr: [String]
    $quoteLink: [String]
    $invoiceLink: [String]
    $toFirstName: String
    $receivedPhoto: Boolean
  ) {
    sendEmailTemp(
      to: $to
      from: $from
      subject: $subject
      text: $text
      type: $type
      attachArr: $attachArr
      quoteLink: $quoteLink
      invoiceLink: $invoiceLink
      toFirstName: $toFirstName
      receivedPhoto: $receivedPhoto
    )
  }
`;

export const CREATE_NEW_CLIENT = gql`
  mutation CreateNewClient(
    $firstName: String!
    $lastName: String
    $primaryEmail: String
    $spouseName: String
    $phoneNumberIds: [PhoneNumUpdate]
    $secondaryEmail: String
    $streetAddress: String
    $city: String
    $state: String
    $zipCode: String
    $clientNotes: ClientNoteInput
    $gateCode: String
    $clientStatus: String
    $existingPhnId: String
  ) {
    createNewClient(
      firstName: $firstName
      lastName: $lastName
      primaryEmail: $primaryEmail
      spouseName: $spouseName
      existingPhnId: $existingPhnId
      phoneNumberIds: $phoneNumberIds
      secondaryEmail: $secondaryEmail
      streetAddress: $streetAddress
      city: $city
      state: $state
      zipCode: $zipCode
      clientNotes: $clientNotes
      gateCode: $gateCode
      clientStatus: $clientStatus
    ) {
      _id
      firstName
      lastName
      spouseName
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
        textMessages {
          body
          sentReceivedTime
          outgoingBool
          twilioSID
          attachedImgs
        }
      }
      primaryEmail
      secondaryEmail
      streetAddress
      city
      state
      zipCode
      gateCode
      clientNotes {
        _id
        note
        dateCreated
        dateUpdated
        updatedBy
        createdBy
      }
      clientStatus
      clientTasks {
        id
        assigneeName
        assigneeId
        taskType
        note
        associatedClientId
        associatedClientName
        active
        dateCreated
        dueDate
      }
    }
  }
`;

export const UPDATE_CLIENT_INFO = gql`
  mutation UpdateClient(
    $clientId: String!
    $firstName: String!
    $lastName: String!
    $primaryEmail: String!
    $spouseName: String
    $phoneNumberIds: [PhoneNumUpdate]
    $secondaryEmail: String
    $streetAddress: String
    $city: String
    $state: String
    $zipCode: String
    $gateCode: String
    $clientNotes: ClientNoteInput
    $clientStatus: String
  ) {
    updateClient(
      clientId: $clientId
      firstName: $firstName
      lastName: $lastName
      primaryEmail: $primaryEmail
      spouseName: $spouseName
      phoneNumberIds: $phoneNumberIds
      secondaryEmail: $secondaryEmail
      streetAddress: $streetAddress
      city: $city
      state: $state
      zipCode: $zipCode
      gateCode: $gateCode
      clientNotes: $clientNotes
      clientStatus: $clientStatus
    ) {
      _id
      firstName
      lastName
      spouseName
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
        textMessages {
          body
          sentReceivedTime
          outgoingBool
          twilioSID
          attachedImgs
        }
      }
      primaryEmail
      secondaryEmail
      streetAddress
      city
      state
      zipCode
      gateCode
      clientNotes {
        _id
        note
        dateCreated
        dateUpdated
        updatedBy
        createdBy
      }
      clientStatus
      clientTasks {
        id
        assigneeName
        assigneeId
        taskType
        note
        associatedClientId
        associatedClientName
        active
        dateCreated
        dueDate
      }
      keapClientId
      keapClientMergeErrors
    }
  }
`;

export const ADD_CLIENT_NOTE = gql`
  mutation Mutation($clientId: String!, $noteObj: ClientNoteInput) {
    addClientNote(clientId: $clientId, noteObj: $noteObj)
  }
`;

export const DELETE_CLIENT_NOTE = gql`
  mutation DeleteClientNote($clientId: String!, $noteId: String!) {
    deleteClientNote(clientId: $clientId, noteId: $noteId)
  }
`;

export const UPDATE_CLIENT_NOTE = gql`
  mutation UpdateClientNote(
    $noteId: String!
    $noteText: String!
    $date: String!
    $userUpdated: String!
  ) {
    updateClientNote(
      noteId: $noteId
      noteText: $noteText
      date: $date
      userUpdated: $userUpdated
    )
  }
`;

export const DELETE_SERVICE_ITEM = gql`
  mutation DeleteServicesItem($itemId: String!) {
    deleteServicesItem(itemId: $itemId)
  }
`;

export const UPDATE_SERVICE_ITEM = gql`
  mutation UpdateServicesItem(
    $itemId: String!
    $itemName: String!
    $discountApplicable: Boolean!
    $price: String!
    $jobType: String
  ) {
    updateServicesItem(
      itemId: $itemId
      itemName: $itemName
      discountApplicable: $discountApplicable
      price: $price
      jobType: $jobType
    ) {
      id
      itemName
      discountApplicable
      price
      jobType
    }
  }
`;

export const ADD_SERVICE_ITEM = gql`
  mutation AddServicesItem(
    $itemName: String!
    $discountApplicable: Boolean!
    $price: String!
    $jobType: String
  ) {
    addServicesItem(
      itemName: $itemName
      discountApplicable: $discountApplicable
      price: $price
      jobType: $jobType
    ) {
      discountApplicable
      id
      itemName
      price
      jobType
    }
  }
`;

export const ADD_BLOCKED_EMAIL = gql`
  mutation AddBlockedEmail($incEmail: String!) {
    addBlockedEmail(incEmail: $incEmail) {
      id
      email
      emailsReceived
    }
  }
`;

export const CREATE_CLIENT_QUOTE = gql`
  mutation CreateClientQuote(
    $quoteStatus: String!
    $quoteType: String
    $dateCreated: String!
    $createdBy: String!
    $quoteTotal: String!
    $subtotal: String!
    $customerName: String!
    $expirationDate: String!
    $discountPercent: String!
    $quoteItems: [QuoteItemsInput]
    $quoteNote: String!
    $customerId: String!
    $assigneeId: String
    $assigneeName: String
  ) {
    createClientQuote(
      quoteStatus: $quoteStatus
      quoteType: $quoteType
      dateCreated: $dateCreated
      createdBy: $createdBy
      quoteTotal: $quoteTotal
      subtotal: $subtotal
      customerName: $customerName
      expirationDate: $expirationDate
      discountPercent: $discountPercent
      quoteItems: $quoteItems
      quoteNote: $quoteNote
      customerId: $customerId
      assigneeId: $assigneeId
      assigneeName: $assigneeName
    ) {
      id
      quoteStatus
      quoteType
      quoteViewed {
        timestamp
        isUser
      }
      dateCreated
      createdBy
      quoteItems {
        itemName
        price
        quantity
        discountApplicable
      }
      quoteTotal
      subtotal
      customerName
      quoteNum
      expirationDate
      discountPercent
      quoteNote
      quoteAccepted
    }
  }
`;

export const MARK_EMAIL_UNREAD = gql`
  mutation MarkEmailUnread($emailId: String!) {
    markEmailUnread(emailId: $emailId)
  }
`;

export const REQ_WEB_AUTHN_CHALLENGE = gql`
  mutation ReqWebAuthnChallenge {
    reqWebAuthnChallenge {
      challenge
      ip
    }
  }
`;

export const DELETE_UNUSED_WEB_AUTHN_CHALLENGE = gql`
  mutation DeleteTempChallengeOnDenyWebAuthn($challenge: String!) {
    deleteTempChallengeOnDenyWebAuthn(challenge: $challenge)
  }
`;

export const CREATE_USER_TASK = gql`
  mutation Mutation(
    $assigneeName: String
    $assigneeId: String
    $taskType: String!
    $note: String
    $associatedClientName: String
    $associatedClientId: String
    $active: Boolean
    $dateCreated: String
    $dueDate: String
  ) {
    createUserTask(
      assigneeName: $assigneeName
      assigneeId: $assigneeId
      taskType: $taskType
      note: $note
      associatedClientName: $associatedClientName
      associatedClientId: $associatedClientId
      active: $active
      dateCreated: $dateCreated
      dueDate: $dueDate
    )
  }
`;

export const MARK_USER_TASK_COMPLETED = gql`
  mutation MarkCompletedTask($taskId: String!, $active: Boolean!) {
    markCompletedTask(taskId: $taskId, active: $active)
  }
`;

export const ACCEPT_CLIENT_QUOTE = gql`
  mutation AcceptClientQuote($quoteId: String!, $localEpoch: String!) {
    acceptClientQuote(quoteId: $quoteId, localEpoch: $localEpoch)
  }
`;

export const CREATE_CLIENT_INVOICE = gql`
  mutation CreateClientInvoice(
    $quoteId: String!
    $localEpoch: String!
    $jobScheduledFor: String!
    $invoicePaid: Boolean
    $invoiceNote: String
    $paidBy: String
  ) {
    createClientInvoice(
      quoteId: $quoteId
      localEpoch: $localEpoch
      jobScheduledFor: $jobScheduledFor
      invoicePaid: $invoicePaid
      invoiceNote: $invoiceNote
      paidBy: $paidBy
    )
  }
`;

export const MARK_INVOICE_PAID = gql`
  mutation MarkInvoicePaid(
    $invoiceId: String!
    $invoicePaid: Boolean!
    $paidBy: String
  ) {
    markInvoicePaid(
      invoiceId: $invoiceId
      invoicePaid: $invoicePaid
      paidBy: $paidBy
    )
  }
`;

export const ARCHIVE_EMAIL_CLEANUP = gql`
  mutation ArchivedEmailCleanup($current: String!) {
    archivedEmailCleanup(current: $current)
  }
`;

export const MARK_EMAIL_FLAG = gql`
  mutation ChangeEmailFlag($emailId: String!, $flag: String!) {
    changeEmailFlag(emailId: $emailId, flag: $flag)
  }
`;

export const SEND_SMS_TEXT = gql`
  mutation SendSMS(
    $textBody: String!
    $localTime: String!
    $phoneNumId: String!
    $sendToNum: String!
  ) {
    sendSMS(
      textBody: $textBody
      localTime: $localTime
      phoneNumId: $phoneNumId
      sendToNum: $sendToNum
    ) {
      _id
      numberType
      phoneNumber
      textMessages {
        body
        sentReceivedTime
        outgoingBool
        twilioSID
        attachedImgs
      }
      title
    }
  }
`;

export const SEND_SMS_TEXT_STRIPPED_RETURN = gql`
  mutation SendSMS(
    $textBody: String!
    $localTime: String!
    $phoneNumId: String!
    $sendToNum: String!
  ) {
    sendSMS(
      textBody: $textBody
      localTime: $localTime
      phoneNumId: $phoneNumId
      sendToNum: $sendToNum
    ) {
      _id
    }
  }
`;

export const ASSIGN_NUM_TO_CLIENT = gql`
  mutation AssignNumToClient(
    $clientFullName: String!
    $clientAlreadyHasId: Boolean!
    $localTime: String!
    $phoneNumId: String!
    $clientId: String!
  ) {
    assignNumToClient(
      clientFullName: $clientFullName
      clientAlreadyHasId: $clientAlreadyHasId
      localTime: $localTime
      phoneNumId: $phoneNumId
      clientId: $clientId
    ) {
      _id
      phoneNumber
      numberType
      title
      recentTime
      unreadMessages
      hasTexts
      textMessages {
        body
        sentReceivedTime
        outgoingBool
        twilioSID
        attachedImgs
      }
    }
  }
`;

export const UPDATE_PHN_MSGS_READ = gql`
  mutation Mutation($phnId: String!, $localEpoch: String!) {
    updatePhnMsgsRead(phnId: $phnId, localEpoch: $localEpoch)
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($amountStr: String!) {
    createPaymentIntent(amountStr: $amountStr)
  }
`;

export const UPDATE_PAYMENT_INTENT = gql`
  mutation UpdatePaymentIntent(
    $amountStr: String!
    $PIId: String!
    $tipStr: String
  ) {
    updatePaymentIntent(amountStr: $amountStr, PIId: $PIId, tipStr: $tipStr)
  }
`;

export const CANCEL_PAYMENT_INTENT = gql`
  mutation CancelPaymentIntent(
    $amountStr: String
    $tipStr: String
    $newPiBool: Boolean!
    $piId: String!
  ) {
    cancelPaymentIntent(
      amountStr: $amountStr
      tipStr: $tipStr
      newPIBool: $newPiBool
      PIId: $piId
    )
  }
`;

export const UPDATE_USER_TASK = gql`
  mutation UpdateUserTask(
    $id: String!
    $assigneeName: String!
    $assigneeId: String!
    $taskType: String!
    $note: String!
    $dueDate: String!
    $dateCreated: String
    $associatedClientId: String
    $associatedClientName: String
    $prevAssignedClient: Boolean!
  ) {
    updateUserTask(
      id: $id
      assigneeName: $assigneeName
      assigneeId: $assigneeId
      taskType: $taskType
      note: $note
      dueDate: $dueDate
      dateCreated: $dateCreated
      associatedClientId: $associatedClientId
      associatedClientName: $associatedClientName
      prevAssignedClient: $prevAssignedClient
    ) {
      id
      assigneeName
      assigneeId
      taskType
      note
      associatedClientId
      associatedClientName
      active
      dateCreated
      dueDate
    }
  }
`;

export const SAVE_STRIPE_TRANSACTION = gql`
  mutation SaveTransaction(
    $stripePIId: String!
    $amount: String!
    $tip: String!
    $total: String!
    $dateCreated: String!
    $invoiceId: String
    $subtotal: String
    $amountMatchesInvoice: Boolean
  ) {
    saveTransaction(
      stripePIId: $stripePIId
      amount: $amount
      tip: $tip
      total: $total
      dateCreated: $dateCreated
      invoiceId: $invoiceId
      subtotal: $subtotal
      amountMatchesInvoice: $amountMatchesInvoice
    ) {
      transactionSaved
      sentEmail
    }
  }
`;

export const KEAP_CLIENT_FETCH_AND_CREATE = gql`
  mutation KeapContactQuery($limit: String!, $offset: String!) {
    keapContactQuery(limit: $limit, offset: $offset) {
      errors
      more
    }
  }
`;

export const ASTERISK_CLEANUP = gql`
  mutation KeapContactQuery($noteArr: [asteriskUpdateInput]) {
    asteriskCleanup(noteArr: $noteArr)
  }
`;

export const CREATE_KEY_SHORTCUT = gql`
  mutation CreateKeyShortcut($title: String!, $text: String!) {
    createKeyShortcut(title: $title, text: $text) {
      id
      text
      title
    }
  }
`;

export const DELETE_KEY_SHORTCUT = gql`
  mutation DeleteKeyShortcut($id: String!) {
    deleteKeyShortcut(id: $id)
  }
`;

export const UPDATE_QUOTE_INVOICE = gql`
  mutation UpdateQuoteInvoice(
    $id: String!
    $isInvoice: Boolean!
    $discountPercent: String!
    $subtotal: String!
    $total: String!
    $dateMills: String
    $itemsArr: [QuoteItemsInput]
    $note: String
  ) {
    updateQuoteInvoice(
      id: $id
      isInvoice: $isInvoice
      discountPercent: $discountPercent
      subtotal: $subtotal
      total: $total
      dateMills: $dateMills
      itemsArr: $itemsArr
      note: $note
    ) {
      invoice {
        id
        invoicePaid
        jobScheduledFor
        relatedTaskId
        dateCreated
        createdBy
        customerId
        invoiceNum
        quoteId
        invoiceNote
        paidBy
        invoiceItems {
          itemName
          price
          quantity
          discountApplicable
        }
        invoiceTotal
        subtotal
        discountPercent
        customerName
      }
      quote {
        id
        quoteStatus
        quoteAccepted
        quoteType
        quoteViewed {
          timestamp
          isUser
        }
        dateCreated
        createdBy
        quoteItems {
          itemName
          price
          quantity
          discountApplicable
        }
        quoteTotal
        subtotal
        customerName
        quoteNum
        expirationDate
        discountPercent
        quoteNote
        customerId
      }
    }
  }
`;

export const RETRIEVE_KEAP_FILE = gql`
  mutation retrieveKeapFile($keapFileId: String!) {
    retrieveKeapFile(keapFileId: $keapFileId)
  }
`;

export const MAKE_KEAP_FILE_MERGE_CALL = gql`
  mutation makeKeapFilesMergeCall($limit: String!, $offset: String!) {
    makeKeapFilesMergeCall(limit: $limit, offset: $offset)
  }
`;
